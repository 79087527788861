<template>
  <div style="width: 100%;height: calc(100% - 28px);overflow: hidden;">
    <div style="width: 100%;height: 100%;">
      <!-- 输入框部分 -->
      <div id="search" class="search-container">
        <el-form :inline="true">

          <!-- 2. 对选择的code进行数据分析的设置 -->
          <el-form-item>
            <span style="margin-left: 2px;">代码</span>
            <el-input v-model="analyzeCode" v-GetFocus placeholder="代码" clearable style="width:100px;" @input="reChange"
              @keyup.enter.native="cjlAnalyze"></el-input>
          </el-form-item>
          <el-form-item>
            <span style="margin-left: 2px;">成交量分析时段</span>
            <el-date-picker v-model="periodValue" type="daterange" align="right" unlink-panels format="yyyy-MM-dd"
              value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
              :picker-options="pickerOptions" size="mini" style="width: 210px;"></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" plain @click="cjlAnalyze">分析</el-button>
          </el-form-item>
          <!-- {{ this.analyzeCode }} -->
        </el-form>
      </div>
      <div>
        <!-- 列表部分 -->
        <!-- :cell-style="{ padding: 0 }" style="width: 100%" border stripe  -->
        <div id="d1" style="width: 228px; float:left;">
          <div id="table" class="mini-table-container">
            <el-table ref="singleTable" :data="codeTable" highlight-current-row @current-change="handleCurrentChange"
              tooltip-effect="dark" :height="tableHeight" :header-row-style="{ height: '30px' }"
              :header-cell-style="{ background: '#f5f7fa', padding: '0px' }" :row-style="rowStyle"
              :cell-style="{ padding: 0 }" style="width: 100%" border stripe>
              <el-table-column label="序号" align="center" width="50">
                <template slot-scope="scope">{{ scope.$index + 1 }}</template>
              </el-table-column>
              <el-table-column prop="tdate" label="tdate" v-if="false"></el-table-column>
              <el-table-column prop="code" label="代码" align="center" width="76"></el-table-column>
              <el-table-column prop="name" label="名称" align="center" width="84"></el-table-column>
            </el-table>
          </div>
        </div>

        <!-- 1月，3月，6月，12月，24月平均成交量表 -->
        <div id="d2" style="float:left; margin:0px 20px 0px 20px;">
          <span style="text-align: center; font-size:16px; font-weight:bold;">名称及代码: </span>
          <span style="font-size:16px;color:rgb(41, 194, 3); font-weight:bold;">{{ gpName }} {{ gpCode }} </span>
          <div id="table" class="mini-table-container" style="margin:8px 0px;">
            <el-table ref="multipleTable" :data="cjlAvgTable" tooltip-effect="dark" size="mini"
              :header-row-style="{ height: '30px' }" :header-cell-style="{ background: '#f5f7fa', padding: '0px' }"
              :row-style="{ height: '20px' }" :cell-style="{ padding: '0px' }" style="width: 100%" border stripe>
              <el-table-column prop="avgtype" label="成交量" align="center" width="120"></el-table-column>
              <el-table-column prop="one" label="1月" align="center" width="120"></el-table-column>
              <el-table-column prop="three" label="3月" align="center" width="120"></el-table-column>
              <el-table-column prop="six" label="6月" align="center" width="120"></el-table-column>
              <el-table-column prop="twelve" label="12月" align="center" width="120"></el-table-column>
              <el-table-column prop="twentyfour" label="24月" align="center" width="120"></el-table-column>
            </el-table>
          </div>
          <div>
            <p>活跃度评估：<span :class="evaluateColor">{{ evaluate | evaluateLevel }} </span></p>
            <p> <span>周平均/月平均 ：&nbsp;&nbsp;{{ rateOneAvg | digFmtDot2 }}</span>&nbsp;&nbsp;&nbsp;
              <span>周平均/月最大 ：&nbsp;&nbsp;{{ rateOneMax | digFmtDot2 }}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>周平均/月最小
                ：&nbsp;&nbsp;&nbsp;{{ rateOneMin | digFmtDot2 }}</span><br />
            </p>
            <p>
              <span>周平均/3月平均 ：{{ rateThree | digFmtDot2 }}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>周平均/6月平均
                ：{{ rateSix | digFmtDot2 }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
              <span>周平均/12月平均：{{ rateTwelve | digFmtDot2
              }}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span>周平均/24月平均：{{ rateTwentyfour | digFmtDot2 }}</span>
            </p>
          </div>
        </div>
      </div>

      <!-- 图形部分 -->
      <!-- hp notebook -->
      <div id="d2" style="width: 970px;  float:left;">
        <!-- <div style="margin-left:20px;margin-top:10px; height:20px;">详情：</div> -->
        <!-- <div id="mychart1" :style="{ width: '970px', height: '240px' }"></div> -->
        <div id="mychart2" :style="{ width: '800px', height: '240px' }"></div>
        <!-- <div id="mychart3" :style="{ width: '970px', height: '240px' }"></div> -->
        <!-- lenvon pc  -->
        <!-- <div id="d2" style="width: 1440px;  float:left;">
      <div style="margin-left:20px;margin-top:10px; height:20px;">详情：</div>
      <div id="mychart1" :style="{ width: '1024px', height: '300px' }"></div>
      <div id="mychart2" :style="{ width: '1024px', height: '300px' }"></div>   -->
        <!-- <el-row :gutter="1">
            <el-col :span="3.5">
              <div class="grid-content bg-purple"></div>
            </el-col>
            <el-col :span="20">
                <div  style="height:100px;">详情：</div>
                <div id="mychart1" :style="{width:'1024px',height:'300px'}"></div>
                <div id="mychart2" :style="{width:'1024px',height:'300px'}"></div>
            </el-col>
          </el-row> -->
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "Character",
  data() {
    return {
      analyzeCode: "",
      gpName: "",
      gpCode: "",
      //下拉框的开始日期、结束日期 值
      startDate: null,
      endDate: null,
      //成交量分析时段下拉日期框
      periodValue: [new Date().getNextDate(-30), new Date().format("yyyy-MM-dd")],

      //下拉日期框快速选择值
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "最近两年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 2);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },

      codeTable: [], //左边 代码列表框中数据
      cjlAvgTable: [],   //月份1，3，6，12，24成交量均值表
      currentRow: { code: "", tdate: "", name: "" },
      evaluate: "",
      rateOneMax: null,
      rateOneAvg: null,
      rateOneMin: null,
      rateThree: null,
      rateSix: null,
      rateTwelve: null,
      rateTwentyfour: null,

      xdatacol2: [],
      ydatacol2: [],
      evaluateColor: "evaluateGreen",

      //位置索引
      positionIndx: [], // 定义一个空数组 positionIndx 用来保存相同 queryCode 的下标；
      positionCode: [], // 定义一个空数组 positionCode 用来保存相同 queryCode 下标对应的实际code ；
      inPosition: 0,
      queryCode: '',
      searchIndex: 1,

      //样式数据
      tableHeight: undefined
    };
  },

  mounted() {
    this.getCodeTable();
  },
  created() {
    this.tableHeight = window.innerHeight - 60 - 36 - 32 - 10;
    this.startDate = new Date().getNextDate(-30);
    this.endDate = new Date().format("yyyy-MM-dd");
    //1.获取具有特征值的所有code 
    //this.getAnalyzeDataList();
  },
  filters: {
    //1、非常高 2、高 3一般 4、偏低 5、低 6、非常低
    evaluateLevel(value) {
      // console.log(" ------=====888=====---------===============  ");
      // console.log("vlue is : " + value);
      if (value === "1") {
        return value = "非常高";
      } else if (value === "2") {
        return value = "高";
      } else if (value === "3") {
        return value = "一般";
      } else if (value === "4") {
        return value = "偏低";
      } else if (value === "5") {
        return value = "低";
      } else if (value === "6") {
        return value = "非常低";
      }
    }
  },
  methods: {
    reChange() {
      this.positionIndx = [];
      this.positionCode = [];
      this.inPosition = 0;
    },
    search() {
      //this.positionIndx = [];
      //this.positionCode = [];
      //1. 寻找所有与查询内容相同的条目的 索引位置。
      // console.log("==================+++++++++++ this.positionIndx  positionCode1 ++++++++++============");
      // console.log("this.analyzeCode: ",this.analyzeCode);
      // console.log("this.positionIndx: ",this.positionIndx);
      // console.log("this.positionCode: ",this.positionCode);
      if (this.analyzeCode.trim() == "") {
        // 初始化和清理搜索定位table时用到的相关 变量数据。
        this.analyzeCode = this.codeTable[0].code;
        this.positionIndx = [];
        this.positionCode = [];
        this.inPosition = 0;
      }
      if (this.positionIndx.length == 0) {
        this.queryCode = this.analyzeCode;
        this.codeTable.forEach((item, index) => {
          if (item.code.includes(this.queryCode) || item.name.includes(this.queryCode)) {
            this.positionIndx.push(index)  // 定义一个空数组 positionIndx 用来保存相同 queryCode 的下标；
            this.positionCode.push(item.code)  // 定义一个空数组 positionCode 用来保存相同 queryCode 下标对应的实际code ；
          }
        })
      }
      // console.log("==================+++++++++++ this.positionIndx  positionCode2 ++++++++++============");
      // console.log(this.positionIndx);
      // console.log(this.positionCode);
      if (this.codeTable.length > 0) {
        this.queryCode = this.analyzeCode;
        //console.log("this.queryCode = this.analyzeCode: ",this.analyzeCode);  
        if (this.queryCode !== '') {
          if (this.$refs['singleTable']) {
            let vmEl = this.$refs['singleTable'].$el            // singleTable 是table中绑定的ref的值，一定要保持一致；
            //计算滚动条的位置
            // console.log("********this.inPosition:   ", this.inPosition);
            // console.log("********this.positionIndx[this.inPosition]:   ",this.positionIndx[this.inPosition]);
            let currentRowIndex = this.positionIndx[this.inPosition];
            var targetTop = vmEl.querySelectorAll('.el-table__body tr')[currentRowIndex].getBoundingClientRect().top    // 找到table中的每一行利用下标来计算每一行dom元素的上部与浏览器的可视窗口的上面的高度距离；
            var containerTop = vmEl.querySelector('.el-table__body').getBoundingClientRect().top
            var scrollParent = vmEl.querySelector('.el-table__body-wrapper')
            scrollParent.scrollTop = targetTop - containerTop;
            this.analyzeCode = this.positionCode[this.inPosition];
            //设置当前搜索匹配行数据高亮
            //this.$refs.singleTable.setCurrentRow(this.$refs.singleTable.data[currentRowIndex]);
            //document.getElementsByClassName('el-table__row')[currentRowIndex].setAttribute('style','background-color:#ff9999');
            this.inPosition++;   // 首先在data中定义 inPosition = 0 ，每次点击search按钮的时候，让下标进行++操作，以遍定位到下一个匹配的 code ；
            if (this.inPosition >= this.positionIndx.length) {
              this.inPosition = 0;                                 // 所有的都遍历完成以后，让定位回到第一个匹配的 code 的行上，以此达到可以循环定位的效果；
            }
          }
          this.queryCode = '';
        } else {
          this.$message({
            type: 'error',
            message: 'Please enter the queryCode of the query'
          })
        }

      }

    },

    getCodeTable() {
      this.$axios.post("/showwy/selection")
        .then(res => {
          this.codeTable = res.data.data;
          if (this.codeTable.length > 0) {
            this.analyzeCode = this.codeTable[0].code;
            //获取该code的成交量分析数据
            this.getActivation();
            this.getCjlGraph();

          }
        });
    },

    getActivation() {
      // console.log("=============***********ActivationVo *********=============");
      // console.log("codeOrNm: this.analyzeCode", this.analyzeCode);
      this.$axios.post("/showwy/activation", {
        codeOrNm: this.analyzeCode,
      }).then(res2 => {
        // console.log("=============***********ActivationVo *********=============");
        // console.log(res2.data.data);
        this.cjlAvgTable = res2.data.data.avgCjlVoLst;
        this.evaluate = res2.data.data.evaluate;
        if (this.evaluate === "1" || this.evaluate === "2" || this.evaluate === "3") {
          this.evaluateColor = "evaluateRed";
        } else {
          this.evaluateColor = "evaluateGreen";
        }
        this.rateOneMax = res2.data.data.weekRateOneMax;
        this.rateOneAvg = res2.data.data.weekRateOneAvg;
        this.rateOneMin = res2.data.data.weekRateOneMin;
        this.rateThree = res2.data.data.weekRateThreeAvg;
        this.rateSix = res2.data.data.weekRateSixAvg;
        this.rateTwelve = res2.data.data.weekRateTwelveAvg;
        this.rateTwentyfour = res2.data.data.weekRateTwentyfourAvg;
      });
    },

    getCjlGraph() {
      // console.log("***********getCjlGraph:  getCjlGraph()**********");
      // console.log(this.analyzeCode);
      // console.log(this.startDate);
      // console.log(this.endDate);

      this.$axios.post("/showwy/linedata", {
        codeOrNm: this.analyzeCode,
        startDate: this.startDate,
        endDate: this.endDate
      })
        .then(res => {
          this.xdatacol2 = res.data.data.tdateLst;
          this.ydatacol2 = res.data.data.cjlLst;
          this.gpName = res.data.data.name;
          this.gpCode = res.data.data.code;
          this.drawLineChart();
        });
    },

    cjlAnalyze() {
      this.startDate = this.periodValue[0];
      this.endDate = this.periodValue[1];
      if (this.analyzeCode.trim() == "") {
        this.analyzeCode = this.codeTable[0].code;
      }
      this.search();
      this.getActivation();
      this.getCjlGraph();

    },

    // 选择处理
    handleCurrentChange(val) {
      if (val) {
        this.currentRow = val;
        this.analyzeCode = this.currentRow.code;
        // 清理搜索定位table时用到的相关 变量数据。
        this.positionIndx = [];
        this.positionCode = [];
        this.inPosition = 0;
      }
    },


    //绘制成交量柱状图

    drawLineChart() {
      // 基于准备好的dom，初始化echarts实例
      let myChart2 = this.$echarts.init(document.getElementById("mychart2"));
      var that = this;
      // #region 柱状图
      myChart2.setOption({
        grid: {
          left: 34,
          right: 40,
          top: 40,
          bottom: 10,
          containLabel: true
        },
        title: [
          {
            top: '2%',
            left: 'center',
            // text: "CjL"
          }
        ],
        tooltip: {
          trigger: 'axis'
        },

        xAxis: {
          data: this.xdatacol2
        },
        yAxis: [
          {
            type: 'value',
            name: "成交量(千万)",
            // axisLabel: {
            //     formatter:'{value}千万'
            //   }
          }
        ],
        series: [
          {
            name: 'cjl',
            type: 'bar',
            data: this.ydatacol2,
            // formatter: '{ this.ydatacol2} qw'
            itemStyle: {//柱体背景色  "#7B68EE",
              normal: {
                color: "#7B68EE"
                // color: function (params) {
                //   let tdate = that.xdatacol2[params.dataIndex];
                //   let characterLst = that.charactLst;
                //   let hasDt = false;
                //   // console.log("========== characterDt =============")
                //   for (let index = 0; index < characterLst.length; index++) {
                //     const charact = characterLst[index];
                //     if (tdate == charact.tdate) {
                //       hasDt = true;
                //     }
                //   }
                //   if (hasDt) {
                //     return "#FC050A";
                //   } else {
                //     return "#90A7FC";
                //   }
                // }
              },
            },
          }
        ]
      });
    },
    //样式控制
    rowStyle() {
      return { height: "34px" };
    },
  }
};

</script>

<style lang="less" scoped>
.el-divider {
  background-color: #b6d7fb;
  height: 20px;
  margin: 0 10px;
}

.search-container /deep/ .el-form-item {
  margin-bottom: 2px;
}

.search-container /deep/ .el-input__inner {
  height: 26px;
  margin-left: 4px;
  line-height: 26px;
}

.search-container /deep/ .el-button {
  padding: 5px 12px;
  margin-left: 4px;
}

.mini-table-container {
  width: 100%;
  height: calc(100% - 36px);
}

用来设置当前页面element全局table 选中某行时的背景色

/* .el-table__body tr.current-row>td{
    background-color: #f19944 !important;
      color: #f19944;  
  } */
.el-row {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #799bcc;
}

.bg-purple {
  background: #9bb9db;
}

.bg-purple-light {
  background: #e8ecf3;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.cjltable {
  background: red;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.evaluateGreen {
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
  color: rgb(41, 194, 3)
}

.evaluateRed {
  padding: 10px;
  font-weight: bold;
  font-size: 16px;
  color: rgb(243, 11, 3)
}

.el-table .success-row {
  background: red !important;
  color: #000;
}
.el-table__body tr.current-row>td{
    background-color: #ff784a !important;
     color: #fff;
  }
</style>